@import '~slick-carousel/slick/slick.css';
@import '~slick-carousel/slick/slick-theme.css';
@import url('https://use.typekit.net/zje4lty.css');

/* @font-face {
  font-family: 'Futura';
  src: local('Futura') url('./fonts/futura_light_bt.ttf'), format('truetype');
  font-weight: normal;
  font-style: normal;
} */

body {
  margin: 0;
  font-family: 'Futura', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto',
    'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.App {
  text-align: center;
}

#responses-table {
  border: 1px solid black;
  margin: 20px;
  width: 80vw;
}

#responses-table th,
#responses-table td {
  border: 1px solid black;
  padding: 0 15px;
}

.odd-row {
  background-color: lightgrey;
}

.slide {
  transform: scale(0.5);
  transition: transform 500ms;
  transition-timing-function: linear;
  position: relative;
}
.active-slide {
  transform: scale(1);
  opacity: 1;
  z-index: 5;
}

.slick-slide {
}

.slick-center {
  z-index: 5;
}

.arrow {
  cursor: pointer;
  filter: invert(99%) sepia(65%) saturate(385%) hue-rotate(327deg)
    brightness(93%) contrast(87%);
}

.dropdown_menu--animated {
  display: none;
  animation: growDown 300ms ease-in-out;
  transform-origin: top center;
  transition: all 300ms ease-in-out;
}
.dropdown_item--animated {
  display: block;
  opacity: 1;
  transition: opacity 300ms ease-in-out;
}
:focus {
  outline-color: #ee9e63;
}

@keyframes growDown {
  0% {
    transform: scaleY(0);
  }
  80% {
    transform: scaleY(1.1);
  }
  100% {
    transform: scaleY(1);
  }
}

@keyframes rotateMenu {
  0% {
    transform: rotateX(-90deg);
  }
  70% {
    transform: rotateX(20deg);
  }
  100% {
    transform: rotateX(0deg);
  }
}

@keyframes downOut {
  0% {
    transform: translateZ(200px) transLateY(40px);
  }
  80% {
    transform: translateZ(-10px) transLateY(0px);
  }
  100% {
    transform: translateZ(0px) transLateY(0px);
  }
}

@keyframes growOut {
  0% {
    transform: scale(0);
  }
  80% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}

@keyframes rotateY {
  0% {
    transform: rotateY(90deg);
  }
  80% {
    transform: rotateY(-10deg);
  }
  100% {
    transform: rotateY(0);
  }
}

@keyframes slideDown {
  0% {
    opacity: 0;
    transform: translateY(-60px);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes rotateX {
  0% {
    opacity: 0;
    transform: rotateX(-90deg);
  }
  50% {
    transform: rotateX(-20deg);
  }
  100% {
    opacity: 1;
    transform: rotateX(0deg);
  }
}

@keyframes rotateZ {
  0% {
    opacity: 0;
    transform: translateZ(290px);
  }

  80% {
    transform: translateZ(10px);
  }

  100% {
    opacity: 1;
    transform: translateZ(0);
  }
}

@keyframes scaleZ {
  0% {
    opacity: 0;
    transform: scale(0);
  }

  80% {
    transform: scale(1.07);
  }

  100% {
    opacity: 1;
    transform: scale(1);
  }
}

@keyframes scaleZ {
  0% {
    opacity: 0;
    transform: scale(0);
  }

  80% {
    transform: scale(1.07);
  }

  100% {
    opacity: 1;
    transform: scale(1);
  }
}

@keyframes translateX {
  0% {
    opacity: 0;
    transform: translateX(60px);
  }

  80% {
    transform: translateX(-5px);
  }

  100% {
    opacity: 1;
    transform: translateX(0px);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}
